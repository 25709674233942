// action - account reducer
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const REGISTER = "REGISTER";
export const FIREBASE_STATE_CHANGED = "FIREBASE_STATE_CHANGED";

// action admin - Login in MERN
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const ADMIN_LOADED = "ADMIN_LOADED";
export const AUTH_FAIL = "AUTH_FAIL";
export const SET_ALERT = "SET_ALERT";
export const DELETE_ALERT = "DELETE_ALERT";
export const DELETE_ALL_ALERT = "DELETE_ALL_ALERT";

export const DASHBOARD_CATEGORY = "DASHBOARD_CATEGORY";
export const INSTRUCTOR_LIST = "INSTRUCTOR_LIST";
export const TOP_COURSE_LIST = "TOP_COURSE_LIST";
export const TOP_RECENT_COURSE_LIST = "TOP_RECENT_COURSE_LIST";
export const INSTRUCTOR_DETAILS = "INSTRUCTOR_DETAILS";
export const INSTRUCTOR_COURSE_LIST = "INSTRUCTOR_COURSE_LIST";
export const SELECTED_CATEGORY = "SELECTED_CATEGORY";
export const TOP_COURSE_DETAILS = "TOP_COURSE_DETAILS";
export const RATING_LIST = "RATING_LIST";
export const SELECTED_INSTRUCTOR_CATEGORY="SELECTED_INSTRUCTOR_CATEGORY"

export const MY_COURSE_LIST = "MY_COURSE_LIST";
export const SELECTED_MY_COURSE_CATEGORY = "SELECTED_MY_COURSE_CATEGORY";
export const GET_CMS_CONTENT="GET_CMS_CONTENT"
export const ALL_NOTIFICATION="ALL_NOTIFICATION";
export const GET_LANGUAGES="GET_LANGUAGES";
export const GET_LABELS_Name="GET_LABELS_Name"

export const CREDIT_LIST="CREDIT_LIST";
export const COURSE_DETAILS="COURSE_DETAILS";
export const  ENROLLMENT_COURSE_LIST="ENROLLMENT_COURSE_LIST";
export const ENROLLMENT_COURSE_DETAILS="ENROLLMENT_COURSE_DETAILS";
export const SELECTED_ENROLLMENT_COURSE_CATEGORY="SELECTED_ENROLLMENT_COURSE_CATEGORY";
export const MY_BANK_DETAILS="MY_BANK_DETAILS";
export const CREDIT_DASHBOARD_LIST="CREDIT_DASHBOARD_LIST";
export const FINANCIAL_DASHBOARD_LIST="FINANCIAL_DASHBOARD_LIST";
export const WITHDRAWAL_LIST="WITHDRAWAL_LIST";
export const DASHBOARD_DATA="DASHBOARD_DATA";
export const MY_COURSE_DETAILS="MY_COURSE_DETAILS";
export const MY_COURSE__EDIT_DETAILS="MY_COURSE__EDIT_DETAILS";
export const PRICE_LIST="PRICE_LIST"