import React,{ useEffect, useRef, useState } from "react";


// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  ClickAwayListener,
  Grid,
  Paper,
  Popper,
  Typography,
  Badge,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
// third-party
import PerfectScrollbar from "react-perfect-scrollbar";
import useRecursiveTimeout from "./useRecursiveTimeout";
// project imports
import MainCard from "ui-component/cards/MainCard";
import Transitions from "ui-component/extended/Transitions";
import NotificationList from "./NotificationList";
import { connect, useSelector } from "react-redux";
// assets
import BellIcon from "assets/images/bell_icon.svg";
import { getNotification,UpdateNotificationCount } from "store/slices/notificationAction";
// ==============================|| NOTIFICATION ||============================== //

const NotificationSection = (props) => {
  const {hallLabelsName}=useSelector((state)=>state.language)
  const { getNotification,UpdateNotificationCount } = props;
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down("md"));
    let language=localStorage.getItem('hall-language-setting') ? localStorage.getItem('hall-language-setting'):"ar"
  const [open, setOpen] = useState(false);
  const [arrowRef, setArrowRef] = useState(null);


  const { notificationList } = useSelector((state) => state.notification);


  const [loading, setLoading] = React.useState(false);
  useEffect(() => {
    setLoading(true);
 getNotification(1, 20, language).then((res) => {
      setLoading(false);
    });
  }, [language]);

  
  useRecursiveTimeout(async () => {
    getNotification(1, 20, language);
  }, 20000);

  const handleWheel = (event) => {
    if (open) {
      event.preventDefault(); // Prevent scrolling when popper is open
    }
  };

  useEffect(() => {
    if (open) {
      window.addEventListener('wheel', handleWheel, { passive: false });
    } else {
      window.removeEventListener('wheel', handleWheel);
    }

    return () => {
      window.removeEventListener('wheel', handleWheel); // Cleanup
    };
  }, [open]);
  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
    UpdateNotificationCount()
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);
  const styles = {
    arrow: {
      position: "absolute",
      fontSize: 7,
      width: "3em",
      height: "3em",
      "&::before": {
        content: '""',
        margin: "auto",
        display: "block",
        width: 0,
        height: 0,
        borderStyle: "solid",
      },
    },
  };

  const StyledPopper = styled(Popper)(({ theme }) => ({
    // You can replace with `PopperUnstyled` for lower bundle size.
    zIndex: 1,
    maxWidth: "375px",
    width: "100%",
    '&[data-popper-placement*="bottom"] .arrow': {
      top: 0,
      left: 0,
      marginTop: "-0.9em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "0 1em 1em 1em",
        borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
      },
    },
    '&[data-popper-placement*="top"] .arrow': {
      bottom: 0,
      left: 0,
      marginBottom: "-0.9em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "1em 1em 0 1em",
        borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
      },
    },
    '&[data-popper-placement*="right"] .arrow': {
      left: 0,
      marginLeft: "-0.9em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 1em 1em 0",
        borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
      },
    },
    '&[data-popper-placement*="left"] .arrow': {
      right: 0,
      marginRight: "-0.9em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 0 1em 1em",
        borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
      },
    },
  }));

  return (
    <>
      <Box
        sx={{
          transition: "all .2s ease-in-out",
          pt: 0.6,
        }}
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Badge
          badgeContent={notificationList.unreadCount}
          color="error"
        >
          <img src={BellIcon} alt="" />
        </Badge>
      </Box>

      <Popper
        placement={matchesXs ? "bottom" : "bottom-end"}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [matchesXs ? -80 : 55, 15],
              },
            },
            {
              name: "arrow",
              enabled: true,
              options: {
                element: arrowRef,
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClose}>
            <Transitions
              position={matchesXs ? "top" : "top-right"}
              in={open}
              {...TransitionProps}
            >
              <Paper>
                {open && (
                  <MainCard
                    border={false}
                    elevation={16}
                    content={false}
                    boxShadow
                    shadow={theme.shadows[16]}
                    //sx={styles.arrow}
                    className="arrow"
                    // ref={setArrowRef}
                    style={{ width: matchesXs ? "320px" : "377px" }}
                  >
                    <Grid container direction="column" spacing={2}>
                      <Grid item xs={12}>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                          sx={{ pt: 2, px: 2,pb:0.5,  flexDirection: language == "en" ? "row" : "row-reverse",  }}
                        >
                          <Grid item>
                            <Typography
                              sx={{ color: "#252525", fontWeight: 600, textAlign: language=="en" ? "left":"right" }}
                            >
                              
                              { hallLabelsName
          ? hallLabelsName.filter((e) => e.key == "lbl_notifications_web")
              .length > 0
            ? hallLabelsName.filter(
                (e) => e.key == "lbl_notifications_web"
              )[0]["value"] || "lbl_notifications_web"
            : "lbl_notifications_web"
          : "lbl_notifications_web"}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} style={{ paddingTop: "0px" }}>
                        <PerfectScrollbar
                          style={{
                            height: "100%",
                            maxHeight: "calc(100vh - 180px)",
                            overflowX: "hidden",
                            width:"100%"
                          }}
                        >
                      <NotificationList data={notificationList.notificationData.docs} count={notificationList.unreadCount} loading={loading}/> 
                        </PerfectScrollbar>
                      </Grid>
                    </Grid>
                  </MainCard>
                )}
              </Paper>
            </Transitions>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
};

export default  connect(null, { getNotification,UpdateNotificationCount })(NotificationSection);
